import logo from './logo.svg';
import './App.css';
import {useState} from 'react';
import {Button, Toast} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { fetchToken, onMessageListener } from './firebase';


function App() {
  
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});
  const [fcmToken, setTokenFound] = useState('');
  fetchToken(setTokenFound);
  onMessageListener().then(payload => {
    setNotification({title: payload.notification.title, body: payload.notification.body})
    setShow(true);
    console.log(payload);
  }).catch(err => console.log('failed: ', err));

  const onShowNotificationClicked = () => {
    setNotification({title: "Notification", body: "This is a test notification"})
    setShow(true);
  }

  return (
    <div className="App">
      
      <Toast onClose={() => setShow(false)} show={show} delay={6500} autohide animation style={{
          position: 'absolute',
          top: 20,
          right: 20,
          minWidth: 200
        }}>
          <Toast.Header>
            <strong className="mr-auto">{notification.title}</strong>
            <small style={{paddingLeft: '4px'}}>just now</small>
          </Toast.Header>
          <Toast.Body>{notification.body}</Toast.Body>
        </Toast>
      <header className="App-header">
        {fcmToken && <><h1> Notification permission enabled 👍🏻 </h1> <div style={{fontSize: '9pt'}}><b>FCMToken:</b> {fcmToken}</div></>}
        {!fcmToken && <h1> Need notification permission ❗️ </h1>}
        <img src={logo} className="App-logo" alt="logo" />
        <Button onClick={() => onShowNotificationClicked()}>Show Toast</Button>
      </header>
    </div>
  );
};

export default App;
