import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyB75i5zJKNPQO8a-mVmdRh7bah-izHMLdk",
  authDomain: "gosmed-platform-messagin-52577.firebaseapp.com",
  projectId: "gosmed-platform-messagin-52577",
  storageBucket: "gosmed-platform-messagin-52577.appspot.com",
  messagingSenderId: "863585048485",
  appId: "1:863585048485:web:b9d6c1e0ae2f52e1387a4c",
  measurementId: "G-BNM8S83F24"
};



const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setToken) => {
    return getToken(messaging, {vapidKey: 'BNetsTcadyHnxGna2UVFT7Mxzuczt1-HWUKS1jHvJv4zUd51MDiteda3rtGOPo239AA5TnKSLa5vrZ4AvXIDw44'}).then((currentToken) => {
      if (currentToken) {
        console.log('FCMToken: ', currentToken);
        setToken(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setToken('');
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  }
  
  export const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
  });
  
